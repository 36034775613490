import React, { useEffect, useState } from 'react';
import Navbar from "../Common/Navbar";
import { primary} from "../Assets/Colors";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";
import Footer from '../Common/Footer';
import WhatsApp from '../Components/Whatsapp';

export default function PrivacyPolicy ()
{
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "ar";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <WhatsApp />
            <section className="relative mx-auto pt-20">
                <div className="relative z-[49] mx-auto max-w-[65rem] w-full px-12 pt-8 sm:p-8 lm:py-16">
                    <h6 className="text-5xl font-semibold">{ t( "Privacy Policy for SpaceTwin" ) }</h6>
                    <h6 className="mt-4">{ t( "Effective September 01, 2024" ) }</h6>
                    <p className="mt-4">{ t( "Welcome to SpaceTwin! Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, www.spacetwin.sa." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "1. Information We Collect" ) }</h6>
                    <p className="mt-4">{ t( "We collect information that you provide directly to us, such as when you create an account, fill out a form, or communicate with us. This information may include personal details like your name, email address, phone number, and any other information you choose to provide." ) }</p>
                    <p className="mt-4">{ t( "We may also automatically collect certain information when you visit our website, including your IP address, browser type, operating system, and the pages you visit on our site. We use cookies and similar technologies to gather this information to improve your experience on our site." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "2. How We Use Your Information" ) }</h6>
                    <p className="mt-4">{ t( "We use the information we collect to:" ) }</p>
                    <ol className='ms-10'>
                        <li className="list-decimal">
                            <p className="mt-2">{ t( "Provide, operate, and maintain our website and services." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "Improve, personalize, and expand our website and services." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "Communicate with you, including sending you updates, promotional materials, and other information related to our services." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "Process your transactions and manage your account." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "Detect and prevent fraud and other illegal activities." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "Understand and analyze how you use our website." ) }</p>
                        </li>
                    </ol>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "3. Sharing Your Information" ) }</h6>
                    <p className="mt-4">{ t( "We do not share your personal information with third parties except in the following cases:" ) }</p>
                    <ol className='ms-10'>
                        <li className="list-decimal">
                            <p className="mt-2">{ t( "With your consent." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "To comply with legal obligations, regulations, or valid governmental requests." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "To protect and defend the rights or property of SpaceTwin." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "To prevent or investigate possible wrongdoing in connection with our website." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "With service providers who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential." ) }</p>
                        </li>
                    </ol>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "4. Security of Your Information" ) }</h6>
                    <p className="mt-4">{ t( "We take the security of your personal information seriously and use reasonable administrative, technical, and physical measures to protect it. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the security of your information." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "5. Your Privacy Rights" ) }</h6>
                    <p className="mt-4">{ t( "Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete the information we have collected from you. To exercise these rights, please contact us using the information provided below." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "6. Changes to This Privacy Policy" ) }</h6>
                    <p className="mt-4">{ t( "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "7. Contact Us" ) }</h6>
                    <p className="mt-4">{ t( "If you have any questions about this Privacy Policy or our data practices, please contact us at privacy@spacetwin.sa." ) }</p>

                    <p className="mt-4">{ t( "Thank you for choosing SpaceTwin!" ) }</p>
                    
                    <br />
                    <br />
                </div>
            </section>
            <Footer />
        </div>
    )
}