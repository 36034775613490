import React, { useEffect, useState } from 'react';
import Navbar from "../Common/Navbar";
import { primary } from "../Assets/Colors";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";
import Footer from '../Common/Footer';
import WhatsApp from '../Components/Whatsapp';

export default function RefundPolicy ()
{
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "ar";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <WhatsApp />
            <section className="relative mx-auto pt-20">
                <div className="relative z-[49] mx-auto max-w-[65rem] w-full px-12 pt-8 sm:p-8 lm:py-16">
                    <h6 className="text-5xl font-semibold">{ t( "SpaceTwin Refund Policy" ) }</h6>
                    <p className="mt-4">{ t( "SpaceTwin is committed to refunding any payments made for software or other services within a fourteen (14) calendar day trial period from the date of the purchase invoice. The customer is entitled to request a refund within this period, provided that the return request is submitted before the trial period ends. The refund request will be processed and the amount refunded within thirty (30) calendar days from the date of approval of the return request, or from the date of receipt of any hardware, if applicable." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "Return Conditions:" ) }</h6>
                    <p className="mt-4">{ t( "The customer's right to a refund is forfeited if the return request is not submitted within the trial period." ) }</p>

                    <p className="mt-4">{ t( "To request a refund, please contact customer service via email at info@Spacetwin.sa or by calling 0558310285 during official working hours." ) }</p>
                    <p className="mt-4">{ t( "This refund policy is subject to the terms and conditions of the subscription that the customer agreed to at the time of registration or subscription to the service. In the event of any conflict between the refund policy and the subscription terms and conditions, the latter shall prevail and be binding. Please review them for additional details." ) }</p>
    
                    <br />
                    <br />
                </div>
            </section>
            <Footer />
        </div>
    )
}