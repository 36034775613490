import Select from 'react-select'
import { primary } from "../Assets/Colors";

export default function SelectInput ( props )
{
    const customStyles = {
        control: ( provided, state ) => ( {
            ...provided,
            borderRadius: '1.5px',
            border: state.isFocused ? `1px solid ${ primary }` : '1px solid #8080804a',
            boxShadow: state.isFocused ? `none` : 'none',
            '&:hover': {
                border: state.isFocused ? `1px solid ${ primary }` : '1px solid #8080804a',
            },
            padding: '0.15rem',
        } ),
        option: ( provided, state ) => ( {
            ...provided,
            backgroundColor: state.isSelected ? primary : '#ffffff',
            color: state.isSelected ? '#ffffff' : '#8c8e9f',
            '&:hover': {
                backgroundColor: primary,
                color: '#ffffff'
            },
        } ),
        placeholder: ( defaultStyles ) => ( {
            ...defaultStyles,
            color: '#b8b8b8', 
        } ),
    };

    return (
        <Select
            name={props.name}
            className="w-full rounded-sm text-sm"
            options={ props.options }
            value={ props.value }
            onChange={ ( value ) => props.setValue( value ) }
            placeholder={ props.placeholder }
            required
            styles={ customStyles }
        />
    );
}
