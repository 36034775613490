import React, { useEffect, useState } from 'react';
import Star1 from "../Assets/svgs/Star1";
import Star2 from "../Assets/svgs/Star2";
import Star3 from "../Assets/svgs/Star3";
import Star4 from "../Assets/svgs/Star4";
import Navbar from "../Common/Navbar";
import Footer from '../Common/Footer';
import img1 from "../Assets/images/news1.gif"
import img2 from "../Assets/images/news2.gif"
import img3 from "../Assets/images/news3.png"
import img4 from "../Assets/images/news4.png"
import { primary } from "../Assets/Colors";
import Update from '../Components/Update';
import { useTranslation } from "react-i18next";
import WhatsApp from '../Components/Whatsapp';

export default function News ()
{
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "ar";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    const updates = [
        {
            title: t( "See What’s Happening Around the Office" ),
            date: t( "July 2, 2024" ),
            text: t( "Click and drag to look around your space, or zoom out to get a birds-eye view. This update makes it easier to see activity in your office." ),
            link: t( "READ MORE" ),
            img: img1
        },
        {
            title: t( "New & Improved Chat" ),
            date: t( "May 26, 2024" ),
            text: t( "Create groups, delete messages, add attachments, and react with emojis! 😄 We’ve revamped Chat to make it easier to interact and share messages with your team." ),
            link: t( "READ MORE" ),
            img: img2
        },
        {
            title: t( "Hand Raise Enhancements" ),
            date: t( "April 28, 2024" ),
            text: t( "Never guess who should speak next. We’ve made several improvements to help you know exactly when teammates have raised their hands, including numbers to indicate the order. You can even toggle whether your hand automatically lowers or not after you speak." ),
            link: t( "DISCOVER BEST PRACTICES FOR MEETINGS ON SpaceTwin" ),
            img: img3
        },
        {
            title: t( "Screen Share Mini Mode Improvements" ),
            date: t( "April 18, 2024" ),
            text: t( "Stay engaged even when presenting in full screen from the SpaceTwin Desktop App. It’s now easier to see meeting participants, chat messages, hand raises, and reactions. Plus you can click-and-drag to resize the window, so it’s always the right size on your screen." ),
            link: t( "DOWNLOAD DESKTOP APP" ),
            img: img4
        }
    ];

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <WhatsApp />
            <header className="relative mx-auto h-80 flex flex-col items-center justify-center">
                <div className="absolute inset-0">
                    <div className="absolute top-[22%] left-[18%] w-3 h-3">
                        <Star1 />
                    </div>
                    <div className="absolute top-[48%] left-[4%] w-3 h-3">
                        <Star2 />
                    </div>
                    <div className="absolute top-[54%] left-[8%] w-3 h-3">
                        <Star3 />
                    </div>
                    <div className="absolute top-[40%] right-[3.6%] w-3 h-3">
                        <Star4 />
                    </div>
                </div>
                <div className="relative z-[49] mx-auto max-w-[85rem] w-full px-12 sm:px-2 lm:px-2 pt-28">
                    <h6 className="text-center text-primary text-5xl font-semibold ltr:tracking-wide my-8">{t("What's New")}</h6>
                    <h6 className="text-center text-xl font-medium leading-8 my-2 w-4/5 mx-auto sm:w-full lm:w-full">{t("Learn about the latest features and fixes")}</h6>
                    <hr className='w-4/5 mx-auto my-12' />
                </div>
            </header>
            <section className="relative flex items-center mx-auto py-2 sm:mt-4 lm:mt-4">
                <div className="mx-auto max-w-[85rem] w-full px-14 sm:px-6 lm:px-6 tab:px-6">
                    <div className='flex flex-col w-3/4 mx-auto sm:w-full lm:w-full tab:w-full'>
                        { updates.map( ( n, index ) => (
                            <Update img={n.img} title={n.title} date={n.date} link={n.link} text={n.text}/>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}