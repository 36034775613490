import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Star1 from "../Assets/svgs/Star1";
import Star2 from "../Assets/svgs/Star2";
import Star3 from "../Assets/svgs/Star3";
import Star4 from "../Assets/svgs/Star4";
import Navbar from "../Common/Navbar";
import Button from "../Components/Button";
import video1 from "../Assets/videos/video1.mp4"
import poster1 from "../Assets/images/poster1.jpg"
import RightArrow from "../Assets/svgs/RightArrow"
import img1 from "../Assets/images/image1.png"
import img2 from "../Assets/images/image2.png"
import img3 from "../Assets/images/image3.png"
import img4 from "../Assets/images/Image4.png"
import Footer from '../Common/Footer';
import BlogCard from '../Components/Blog';
import TrySpaceTwin from '../Components/TrySpaceTwin';
import { primary, lightPrimary } from "../Assets/Colors";
import Reviews from '../Common/Reviews';
import Teams from '../Common/Teams';
import rpimg1 from '../Assets/images/rp4.png'
import rpimg2 from '../Assets/images/rp5.png'
import rpimg3 from '../Assets/images/rp6.png'
import tsp1 from '../Assets/images/tsp2.png'
import blog1 from '../Assets/images/blog1.png'
import blog2 from '../Assets/images/blog2.png'
import blog3 from '../Assets/images/blog3.png'
import { useNavigate } from "react-router-dom";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";
import WhatsApp from '../Components/Whatsapp';

export default function Features ()
{
    const navigate = useNavigate();
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "ar";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    const navItems = [
        {
            heading: t( "Design your ideal office" ),
            text1: t( "Access the office no matter where you’re located and enjoy team productivity and collaboration." ),
            point1: t( "Quick-Start Templates" ),
            exp1: t( "Instantly get started with office templates, ranging in size for small to large teams." ),
            point2: t( "Full Customization" ),
            exp2: t( "Easily add or remove objects, or use the MapMaker to design a custom office showing your company’s personality." ),
            point3: t( "Space for Every Style of Work" ),
            exp3: t( "Create designated areas for meetings, focus work, or social co-working. There’s always room for your team to work the way they work best." ),
            img: img1,
        },
        {
            heading: t( "Hold productive conversations" ),
            text1: t( "Interact in real-time with visual context, leading to quick decisions and more creative ideas." ),
            point1: t( "Book Meetings" ),
            exp1: t( "Connect your Google or Outlook Calendar or add our Slack integration to easily schedule meetings in SpaceTwin." ),
            point2: t( "Spontaneous Conversations" ),
            exp2: t( "Walk in and out of conversations just as fluidly as real life." ),
            point3: t( "Collaboration Tools" ),
            exp3: t( "Ideate together on a whiteboard, share multiple screens at the same time, or embed links in objects for easy in-office access." ),
            point4: t( "Emotes and Reactions" ),
            exp4: t( "React in real-time to share how you’re feeling, cast a vote, or raise your hand to ask the next question." ),
            img: img2,
        },
        {
            heading: t( "Build stronger remote relationships" ),
            text1: t( "SpaceTwin gives you context into who your team is and how they work best, leading to better collaboration and communication." ),
            point1: t( "Personalize Avatars" ),
            exp1: t( "Bring your authentic avatar to work. Every member of your team can select their appearance and express themselves each day." ),
            point2: t( "Customize Desks" ),
            exp2: t( "You can learn a lot about someone just by looking at their desk. SpaceTwin makes it easy to share your personality with your coworkers." ),
            point3: t( "Social Experiences" ),
            exp3: t( "Facilitate team activities, hold ice breakers, or take a break with built-in social games." ),
            img: img3,
        },
    ];

    const reviews = [
        {
            image: rpimg1,
            name: t( "Carolina Kia" ),
            designation: t( "CEO at weme" ),
            review: t( "When we think about remote work, we think about asynchronous or synchronous communication, but SpaceTwin enables spontaneous conversations. It’s different when you have a spontaneous conversation than planned communication." )
        },
        {
            image: rpimg2,
            name: t( "Jonas Morgner" ),
            designation: t( "People Area Manager at Redspark" ),
            review: t( "It was quickly apparent that on SpaceTwin, it is easy to facilitate human connection among people even if you are remote. You can converse with someone next to you in the office. You see each other while you are working. It is easy to have all of our meetings in this digital space." )
        },
        {
            image: rpimg3,
            name: t( "Tobias Nendel" ),
            designation: t( "CTO & Co-Founder of TradeLink" ),
            review: t( "We love our virtual office because it lets us keep the spontaneity of an in-person office alive." )
        }
    ];

    const blogs = [
        {
            image: blog1,
            heading: t( "Getting Started with SpaceTwin" ),
        },
        {
            image: blog2,
            heading: t( "Your SpaceTwin Office Floor Plan" ),
        },
        {
            image: blog3,
            heading: t( "Bringing Your Team to SpaceTwin" ),
        }
    ];

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <WhatsApp />
            <header className="relative mx-auto h-120vh sm:h-130vh lm:h-130vh bg-black">
                <div className="absolute inset-0">
                    <div className="absolute top-[22%] left-[18%] w-3 h-3">
                        <Star1 />
                    </div>
                    <div className="absolute top-[48%] left-[4%] w-3 h-3">
                        <Star2 />
                    </div>
                    <div className="absolute top-[54%] left-[8%] w-3 h-3">
                        <Star3 />
                    </div>
                    <div className="absolute top-[40%] right-[3.6%] w-3 h-3">
                        <Star4 />
                    </div>
                </div>
                <div className="flex sm:flex-col lm:flex-col items-center relative z-[49] mx-auto max-w-[85rem] w-full px-12 sm:p-2 lm:p-2">
                    <div className="w-1/2 py-16 mt-32 pr-2 sm:pt-16 lm:pt-16 sm:pb-0 lm:pb-0 sm:w-full lm:w-full sm:mt-20 lm:mt-20">
                        <h6 className="text-white sm:text-center lm:text-center text-5xl sm:text-4xl lm:text-4xl font-semibold leading-tight ltr:tracking-wider">{ t( "A space for connections and conversations" ) }</h6>
                        <h6 className="text-xl sm:text-center lm:text-center font-medium leading-8 my-2 sm:my-5 lm:my-5 text-tertiary">{ t( "SpaceTwin brings the best of in-person collaboration to distributed teams." ) }</h6>
                        <div className="flex sm:flex-col items-center mt-5 sm:justify-center lm:justify-center">
                            <Button onClick={ () => { window.location.href = "https://office.spacetwin.sa/"; } } text={ t( "Sign Up" ) } width={ size.width > 300 && size.width < 531 ? "80%" : "8.2rem" } height="3rem" background={ lightPrimary } color="white" border={ `0.02rem solid white` } />
                            {/* <a href='/take-a-tour' className='flex sm:mt-4'>
                                <h6 className="text-sm text-white font-bold ml-5 mr-2">{ t( "Or take a tour" ) }</h6>
                                <RightArrow fill="white" height="1.5rem" width="1.5rem" style={ {
                                    transform: document.documentElement.dir === 'rtl' ? 'rotate(180deg)' : 'none',
                                    transition: 'transform 0.3s ease',
                                    height: document.documentElement.dir === 'rtl' ? '2rem' : '1.5rem',
                                    width: document.documentElement.dir === 'rtl' ? '2rem' : '1.5rem',
                                } } />
                            </a> */}
                        </div>
                    </div>
                    <div className="w-1/2 mt-44 sm:w-full lm:w-full sm:mt-6 lm:mt-6">
                        <video src={ video1 } className="w-4/5 sm:w-full lm:w-full h-96 sm:h-80 lm:h-80 ml-auto sm:m-1 lm:m-1" poster={ poster1 } muted loop autoPlay></video>
                    </div>
                </div>
            </header>
            <Teams />
            <section className="bg-white w-full">
                <div className="mx-auto max-w-[85rem] w-full px-12 sm:p-2 lm:p-2">
                    { navItems.map( ( n, index ) =>
                    (
                        <div key={ index } className="my-8 flex px-4 sm:p-2 lm:p-2 sm:mt-20 lm:mt-20">
                            { ( index + 1 ) % 2 === 0 ?
                                <div className='flex sm:flex-col lm:flex-col'>
                                    <div className="w-1/2 sm:w-full lm:w-full sm:p-2 lm:p-2 flex justify-center items-center py-8 px-6">
                                        <img src={ n.img } alt="Image" />
                                    </div>
                                    <div className="w-1/2 sm:w-full lm:w-full px-14 py-4 sm:p-2 lm:p-2 flex flex-col sm:mt-12 lm:mt-12">
                                        <span className="relative text-primary font-semibold text-3xl">{ n.heading }</span>
                                        <p className="text-base leading-tight mr-3 my-2 ltr:tracking-wide">{ n.text1 }</p>
                                        <ul>
                                            <li className='text-xl font-semibold mt-3'>{ n.point1 }</li>
                                            <p>{ n.exp1 }</p>
                                            <li className='text-xl font-semibold mt-3'>{ n.point2 }</li>
                                            <p>{ n.exp2 }</p>
                                            <li className='text-xl font-semibold mt-3'>{ n.point3 }</li>
                                            <p>{ n.exp3 }</p>
                                            <li className='text-xl font-semibold mt-3'>{ n.point4 }</li>
                                            <p>{ n.exp4 }</p>
                                        </ul>
                                    </div>
                                </div>
                                :
                                <div className='flex sm:flex-col-reverse lm:flex-col-reverse'>
                                    <div className="w-1/2 sm:w-full lm:w-full px-14 py-20 sm:p-2 lm:p-2 flex flex-col sm:mt-12 lm:mt-12 justify-center">
                                        <span className="text-primary font-semibold text-3xl">{ n.heading }</span>
                                        <p className="text-base leading-tight mr-3 my-4 ltr:tracking-wide">{ n.text1 }</p>
                                        <ul>
                                            <li className='text-xl font-semibold mt-3'>{ n.point1 }</li>
                                            <p>{ n.exp1 }</p>
                                            <li className='text-xl font-semibold mt-3'>{ n.point2 }</li>
                                            <p>{ n.exp2 }</p>
                                            <li className='text-xl font-semibold mt-3'>{ n.point3 }</li>
                                            <p>{ n.exp3 }</p>
                                        </ul>
                                    </div>
                                    <div className="w-1/2 sm:w-full lm:w-full sm:p-2 lm:p-2 flex justify-center items-center py-8 px-6">
                                        <img src={ n.img } alt="Image" />
                                    </div>
                                </div>
                            }
                        </div>
                    ) ) }
                </div>
            </section>
            <Reviews reviews={ reviews } />
            <section className="mx-auto bg-white py-24 sm:py-12 lm:py-12">
                <div className="flex sm:flex-col-reverse lm:flex-col-reverse items-center relative z-[49] mx-auto max-w-[85rem] w-full px-12 sm:p-2 lm:p-2">
                    <div className="w-1/2 pr-12 sm:w-full lm:w-full sm:p-2 lm:p-2">
                        <h6 className="text-primary text-4xl font-semibold">{ t( "Host a virtual conference" ) }</h6>
                        <h6 className="text-lg font-medium leading-8 my-2">{ t( "Engage your community with a virtual event space on SpaceTwin. Create breakout rooms, give individual booths to partners, and make it easy for attendees to network together." ) }</h6>
                        {/* <div className="flex items-center mt-5">
                            <Button onClick={ () => { navigate( "/use-cases/virtual-conferences" ); } } text={ t( "Learn more" ) } width="100%" height="3rem" background={ primary } color="white" border={ `0.02rem solid white` } />
                        </div> */}
                    </div>
                    <div className="w-1/2 sm:w-full lm:w-full sm:pb-6 lm:pb-6">
                        <img className='w-full h-96 px-4 sm:p-2 lm:p-2' src={ img4 } alt="Image" />
                    </div>
                </div>
            </section>
            <section className="w-full  bg-gradient-to-b from-bg-gradient-start via-bg-gradient-mid to-bg-gradient-end">
                <div className="mx-auto max-w-[85rem] w-full px-12 py-4 sm:p-2 lm:p-2">
                    <h6 className="text-white text-center text-5xl sm:text-4xl lm:text-4xl font-semibold mx-auto my-16 leading-tight ltr:tracking-wider">{ t( "Learn more about working in SpaceTwin" ) }</h6>
                    <div className='flex sm:flex-wrap lm:flex-wrap items-center justify-between mb-12'>
                        { blogs.map( ( n, inde ) => (
                            <div className='w-4/12 sm:w-full lm:w-full sm:py-4 lm:py-4'>
                                <BlogCard image={ n.image } text={ n.heading } />
                            </div>
                        ) ) }
                    </div>
                </div>
            </section>
            <TrySpaceTwin text={ t( "Build a culture your remote team loves" ) } img={ tsp1 } />
            <Footer />
        </div>
    )
}