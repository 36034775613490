import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsApp = () =>
{
    const phoneNumber = '+966558310285';
    const message = 'Hello! I would like to chat with you.';

    const openWhatsApp = () =>
    {
        const url = `https://wa.me/${ phoneNumber }?text=${ encodeURIComponent( message ) }`;
        window.open( url, '_blank' );
    };

    return (
        <div className="fixed bottom-4 end-4 z-50">
            <button className="p-2 hover:bg-[#2ca458] hover:transition-all bg-[#25D366] text-white rounded-full border-none cursor-pointer flex items-center justifu-center shadow-md" onClick={ openWhatsApp }>
                <FaWhatsapp size={ 40 } />
            </button>
        </div>
    );
};

export default WhatsApp;