import ReviewCard from '../Components/Review';
import { primary } from "../Assets/Colors";
import Button from "../Components/Button";
import { useNavigate } from "react-router-dom";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";

export default function Reviews (props)
{
    const navigate = useNavigate();
    const size = useWindowSize();
    const { i18n, t } = useTranslation();

    return (
        <section className="w-full bg-[#F7F5F4]">
            <div className="mx-auto max-w-[85rem] w-full sm:p-2 lm:p-2 tab:p-2 px-12 py-4">
                <h6 className="text-center text-5xl sm:text-4xl lm:text-4xl tab:text-4xl font-semibold mx-auto my-16 leading-tight ltr:tracking-wider">{t("Why remote teams love SpaceTwin")}</h6>
                <div className='flex sm:flex-col lm:flex-col tab:flex-col items-start justify-between mb-12'>
                    { props.reviews.map( ( n, key ) => (
                        <div className='w-4/12 sm:w-full lm:w-full tab:w-full sm:my-4 lm:my-4 tab:my-4'>
                            <ReviewCard name={ n.name } image={n.image} desg={n.designation} review={n.review} />
                        </div>
                    ))}
                </div>
                {/* <div className='flex items-center justify-center mb-12'>
                    <Button onClick={ () => { navigate( "/customer-stories" ); } } text={t("Read all Customer Stories")} width={ size.width > 300 && size.width < 531 ? "90%" : "14rem" } height="3rem" background={ primary } color="white" />
                </div> */}
            </div>
        </section>
    )
}