import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Star1 from "../Assets/svgs/Star1";
import Star2 from "../Assets/svgs/Star2";
import Star3 from "../Assets/svgs/Star3";
import Star4 from "../Assets/svgs/Star4";
import Navbar from "../Common/Navbar";
import Button from "../Components/Button";
import video1 from "../Assets/videos/video1.mp4"
import video2 from "../Assets/videos/video2.mp4"
import poster1 from "../Assets/images/poster1.jpg"
import RightArrow from "../Assets/svgs/RightArrow"
import img1 from "../Assets/images/img1.png"
import img2 from "../Assets/images/img2.png"
import img3 from "../Assets/images/img3.png"
import screen from "../Assets/svgs/Screen.svg"
import calender from "../Assets/svgs/Calendar.svg"
import message from "../Assets/svgs/Message.svg"
import heart from "../Assets/svgs/Heart.svg"
import ReviewCard2 from "../Components/Review2";
import Footer from '../Common/Footer';
import { primary, secondary, btnText1 } from "../Assets/Colors";
import { useNavigate } from "react-router-dom";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";
import task from "../Assets/svgs/Task.svg";
import office from "../Assets/svgs/Office.svg"
import WhatsApp from '../Components/Whatsapp';

export default function Index ()
{
    const navigate = useNavigate();
    const [ isScrolledDown, setIsScrolledDown ] = useState( false );
    const size = useWindowSize();
    const { i18n, t } = useTranslation();

    const navItems = [
        {
            line1: t( "PROXIMITY AND VISIBILITY" ),
            line2: t( "Bring your remote team closer together" ),
            line3: t( "Communicate, collaborate, and feel more connected in a persistent space that reflects your unique team culture." ),
            img: img1,
        }, {
            line1: t( "SERENDIPITOUS MOMENTS" ),
            line2: t( "Talk naturally throughout your day" ),
            line3: t( "Stop by someone's desk, say hi in the hallway, and bring back water cooler chats. No scheduling required." ),
            img: img2,
        }, {
            line1: t( "PRODUCTIVE CONVERSATIONS" ),
            line2: t( "Meet in the moment" ),
            line3: t( "Collaborate in the moment or schedule team meetings to keep everyone aligned and work moving forward." ),
            img: img3,
        },
    ];

    const remoteIcons = [
        {
            icon: screen,
            text: t( "Screen sharing" )
        },
        {
            icon: task,
            text: t( "Task management" )
        },
        {
            icon: office,
            text: t( "Private and public areas" )
        },
        {
            icon: calender,
            text: t( "Video and audio meetings" )
        },
        {
            icon: message,
            text: t( "Collaborate on shared whiteboards" )
        },
        {
            icon: heart,
            text: t( "And more!" )
        }
    ];

    useEffect( () =>
    {
        const handleScroll = () =>
        {
            const scrollPosition = window.scrollY;

            if ( scrollPosition > 100 )
            {
                setIsScrolledDown( true );
            } else
            {
                setIsScrolledDown( false );
            }
        };

        window.addEventListener( 'scroll', handleScroll );

        return () =>
        {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [] );

    const navbarProps = {
        bg: isScrolledDown ? 'bg-white' : 'bg-transparent',
        text: isScrolledDown || size.width < 767 ? 'text-black' : 'text-white',
        logoFill: isScrolledDown || size.width < 267 ? '#000000' : '#fff',
        btnbg: secondary,
        btntext: btnText1,
        bgMobile: isScrolledDown ? 'bg-white' : 'bg-black',
    };

    return (
        <div className="w-full">
            <Navbar { ...navbarProps } />
            <WhatsApp/>
            <header className="relative mx-auto h-130vh lm:h-148vh tab:h-120vh bg-black">
                <div className="absolute inset-0">
                    <div className="absolute top-[22%] left-[18%] w-3 h-3">
                        <Star1 />
                    </div>
                    <div className="absolute top-[48%] left-[4%] w-3 h-3">
                        <Star2 />
                    </div>
                    <div className="absolute top-[54%] left-[8%] w-3 h-3">
                        <Star3 />
                    </div>
                    <div className="absolute top-[40%] right-[2.2%] w-3 h-3">
                        <Star4 />
                    </div>
                </div>
                <div className="flex sm:flex-col lm:flex-col items-center relative z-[49] mx-auto max-w-[85rem] w-full px-8 md:px-8 lg:px-12">
                    <div className="w-1/2 sm:w-full lm:w-full py-4 mt-64 sm:mt-32 lm:mt-32 pr-12 sm:p-2 lm:p-2">
                        <h1 className="sm:text-center lm:text-center text-6xl sm:text-5xl lm:text-5xl font-bold ltr:tracking-wider text-primary">{ t( "Your" ) } <span className="text-secondary">{ t( "Virtual HQ" ) }</span></h1>
                        <h6 className="text-xl sm:text-center lm:text-center text-white font-medium leading-8 my-2">{ t( "SpaceTwin brings the best of in-person collaboration to distributed teams." ) }</h6>
                        <div className="flex items-center mt-5 sm:absolute sm:-bottom-28 sm:w-4/5 lm:w-4/5 sm:justify-center lm:justify-center sm:mx-auto lm:mx-auto">
                            <Button onClick={ () => { window.location.href = "https://office.spacetwin.sa/"; } } text={ t( "Sign Up" ) } width="8rem" height="2.8rem" background={ secondary } color={ btnText1 } />
                            {/* <a href='/take-a-tour' className='flex sm:hidden'>
                                <h6 className="text-sm text-white font-bold ml-5 mr-2 pt-0.5 ">{ t( "Or take a tour" ) }</h6>
                                <RightArrow fill="white" height="1.5rem" width="1.5rem" style={ {
                                    transform: document.documentElement.dir === 'rtl' ? 'rotate(180deg)' : 'none',
                                    transition: 'transform 0.3s ease',
                                    height: '1.5rem',
                                    width: '1.5rem',
                                } } />
                            </a> */}
                        </div>
                    </div>
                    <div className="w-1/2 sm:w-full lm:w-full mt-44 sm:mt-6 lm:mt-6">
                        <video src={ video1 } className="rounded-3xl w-full shadow-shadowVideo" poster={ poster1 } muted loop autoPlay></video>
                    </div>
                </div>
            </header>
            <section className="bg-white w-full">
                <div className="mx-auto max-w-[85rem] w-full px-12 pb-5 pt-2 sm:p-3 lm:p-3 tab:p-2">
                    <h6 className="px-44 sm:px-2 lm:px-2 text-center text-4.5xl font-bold my-12 leading-tight ltr:tracking-wider sm:my-6 lm:my-6">{ t( "The in-person moments you’ve been missing" ) }</h6>
                    { navItems.map( ( n, index ) =>
                    (
                        <div key={ index } className="my-8 flex px-4">
                            { ( index + 1 ) % 2 === 0 ?
                                <div className='flex sm:flex-col-reverse lm:flex-col-reverse'>
                                    <div className="w-1/2 sm:w-full lm:w-full flex justify-center items-center py-8 pl-8 sm:p-1 lm:p-1">
                                        <img src={ n.img } alt="Image" />
                                    </div>
                                    <div className="w-1/2 sm:w-full lm:w-full px-14 py-20 sm:p-2 lm:p-2 flex flex-col justify-center">
                                        <h6 className="text-black font-bold text-base ">{ n.line1 }</h6>
                                        <h6 className="text-primary font-bold text-4xl sm:text-3xl lm:text-3xl leading-tight mr-3 my-4 ltr:tracking-wide sm:my-2 lm:my-2">{ n.line2 }</h6>
                                        <h6 className="text-base font-medium ">{ n.line3 }</h6>
                                    </div>
                                </div>
                                :
                                <div className='flex sm:flex-col lm:flex-col'>
                                    <div className="w-1/2 sm:w-full lm:w-full px-14 py-20 sm:p-2 lm:p-2 flex flex-col justify-center">
                                        <h6 className="text-black font-semibold text-base ">{ n.line1 }</h6>
                                        <h6 className="text-primary font-bold text-4xl sm:text-3xl lm:text-3xl leading-tight mr-3 my-4 ltr:tracking-wide sm:my-2 lm:my-2">{ n.line2 }</h6>
                                        <h6 className="text-base font-medium ">{ n.line3 }</h6>
                                    </div>
                                    <div className="w-1/2 sm:w-full lm:w-full flex justify-center items-center py-8 pr-8 sm:p-1 lm:p-1">
                                        <img src={ n.img } alt="Image" />
                                    </div>
                                </div>
                            }
                        </div>
                    ) ) }
                </div>
            </section>

            <section className="relative w-full bg-gradient-to-b from-bg-gradient-start via-bg-gradient-mid to-bg-gradient-end">
                <div className="absolute inset-0">
                    <div className="absolute top-[22%] left-[18%] w-3 h-3">
                        <Star1 />
                    </div>
                    <div className="absolute top-[48%] left-[4%] w-3 h-3">
                        <Star2 />
                    </div>
                    <div className="absolute top-[54%] left-[8%] w-3 h-3">
                        <Star3 />
                    </div>
                    <div className="absolute top-[40%] right-[3.6%] w-3 h-3">
                        <Star4 />
                    </div>
                </div>
                <div className="mx-auto max-w-[85rem] w-full px-8 py-5 relative z-[49]">
                    <h6 className="px-52 text-center text-5xl sm:text-4xl lm:text-4xl text-white font-bold mx-auto mt-16 leading-tight ltr:tracking-wider sm:my-4 lm:my-4 sm:p-2 lm:p-2 tab:p-2">{ t( "Everything remote teams need to get work done" ) }</h6>
                    <div className="border border-[#ffffff4a] w-3/5 sm:w-full lm:w-full mt-16 mb-16 pt-4 py-12 mx-auto bg-[#ffffff24] flex flex-wrap rounded-2xl sm:p-2 lm:p-2 sm:m-2 lm:m-2">
                        { remoteIcons.map( ( n, index ) => (
                            <div className="w-1/2 sm:hidden lm:hidden flex py-1">
                                <div className="w-3/12 flex items-center justify-center">
                                    <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center my-4 p-2">
                                        <img src={ n.icon } alt="screen" />
                                    </div>
                                </div>
                                <div className="w-9/12 text-base font-medium text-white flex items-center pe-16">{ n.text }</div>
                            </div>
                        ) ) }
                        <div className='sm:block lm:block hidden w-full h-full'>
                            <Swiper
                                slidesPerView={ 1 }
                                spaceBetween={ 30 }
                                pagination={ {
                                    clickable: true,
                                } }
                                loop={ true }
                                modules={ [ Pagination ] }
                                className="my-2 h-100"
                            >
                                <SwiperSlide>
                                    { remoteIcons.slice( 0, 4 ).map( ( n, index ) => (
                                        <div className="w-1/2 sm:w-full lm:w-full flex pt-2.5">
                                            <div className="w-3/12 flex items-center justify-center">
                                                <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center my-2.5 p-2">
                                                    <img src={ n.icon } alt="screen" />
                                                </div>
                                            </div>
                                            <div className="w-9/12 text-base sm:text-lg lm:text-lg font-medium text-white flex items-center pe-16 sm:pe-2 lm:pe-2">{ n.text }</div>
                                        </div>
                                    ) ) }
                                </SwiperSlide>
                                <SwiperSlide>
                                    { remoteIcons.slice( 4, 6 ).map( ( n, index ) => (
                                        <div className="w-1/2 sm:w-full lm:w-full flex pt-2.5">
                                            <div className="w-3/12 flex items-center justify-center">
                                                <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center my-2.5 p-2">
                                                    <img src={ n.icon } alt="screen" />
                                                </div>
                                            </div>
                                            <div className="w-9/12 text-base sm:text-lg lm:text-lg font-medium text-white flex items-center pe-16 sm:pe-2 lm:pe-2">{ n.text }</div>
                                        </div>
                                    ) ) }
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w-full bg-[#F7F5F4]">
                <div className="mx-auto max-w-[85rem] w-full px-8 py-4">
                    <h6 className="text-center text-5xl sm:text-4.5xl lm:text-4.5xl font-bold mx-auto mt-16 leading-tight ltr:tracking-wider">{ t( "Why distributed teams love SpaceTwin" ) }</h6>
                    <Swiper
                        slidesPerView={ 3 }
                        spaceBetween={ 30 }
                        pagination={ {
                            clickable: true,
                        } }
                        loop={ true }
                        modules={ [ Pagination ] }
                        className="my-16 h-100"
                        breakpoints={ {
                            1200: { // sl
                                slidesPerView: 3,
                            },
                            993: { // sl
                                slidesPerView: 2,
                            },
                            768: { // tab
                                slidesPerView: 2,
                            },
                            531: { // lm
                                slidesPerView: 2,
                            },
                            300: { // sm
                                slidesPerView: 1,
                                spaceBetween: 10 
                            },
                        } }
                    >
                        <SwiperSlide>
                            <ReviewCard2 />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ReviewCard2 />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ReviewCard2 />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ReviewCard2 />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ReviewCard2 />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="w-full bg-white border-b border-[#d2d2d2]">
                <div className="max-w-[85rem] w-full px-8 sm:p-2 lm:p-2 mx-auto py-16 flex flex-col items-center">
                    <div className='w-3/4 sm:w-[98%] lm:w-[98%]'>
                        <video src={ video2 } className="rounded-3xl w-full" poster={ poster1 } muted loop autoPlay></video>
                    </div>
                    <h6 className="text-center text-5xl sm:text-4xl lm:text-4xl font-semibold mx-auto mt-16 leading-tight ltr:tracking-wider">{ t( "Build a culture your remote team loves" ) }</h6>
                    <div className="flex w-full justify-center sm:flex-col items-center mt-10 mb-2">
                        <Button onClick={ () => { window.location.href = "https://office.spacetwin.sa/"; } } text={ t( "Sign Up" ) }
                            width={ size.width > 300 && size.width < 531 ? "80%" : "8.2rem" }
                            height="3rem" fontWeight="600" background={ primary } color="white" />
                        <a href='/pricing' className='flex sm:mt-6'>
                            <h6 className="text-sm sm:text-base lm:text-base text-primary font-semibold ml-5 mr-2 pt-0.5">{ t( "View Pricing" ) }</h6>
                            <RightArrow fill={ primary } height="1.5rem" width="1.5rem" style={ {
                                transform: document.documentElement.dir === 'rtl' ? 'rotate(180deg)' : 'none',
                                transition: 'transform 0.3s ease',
                                height: '1.5rem',
                                width: '1.5rem',
                            } } />
                        </a>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}