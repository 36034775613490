import React, { useEffect, useState } from 'react';
import Logo from "../Assets/svgs/Logo";
import LinkedIn from "../Assets/svgs/linkedin.svg"
import Facebook from "../Assets/svgs/facebook.svg"
import Instagram from "../Assets/svgs/insta.svg"
import Twitter from "../Assets/svgs/twitter.svg"
import { useTranslation } from "react-i18next";

export default function Footer ()
{
    const { i18n, t } = useTranslation();

    const footerLinks = {
        [t("Product")]: {
            items: [
                { label: t("Features"), link: "/features" },
                { label: t("Inegrations"), link: "/integrations" },
                { label: t("Privacy & Security"), link: "/security" },
                { label: t("What's new"), link: "/whats-new" }
            ]
        },
        [t("Company")]: {
            items: [
                { label: t("About"), link: "#" },
                { label: t("Careers"), link: "#" },
                { label: t("Contact Us"), link: "/contact-sales" }
            ]
        },
    };
    return (
        <div className="flex h-84 w-full sm:h-auto lm:h-auto tab:h-auto bg-black text-white">
            <div className="max-w-[85rem] w-full px-8 mx-auto flex py-6 sm:flex-col-reverse">
                <div className="w-2/5 sl:w-[30%] sm:w-full lm:w-full tab:w-full">
                    <Logo fill="white" width="10rem" height="8rem" />
                    <div className="text-sm font-medium m-2">{t("2023 SpaceTwin Presence Inc.")}</div>
                    <div className="text-sm font-medium m-2">{ t("Phone: 0 55 831 0285")}</div>
                    <div className="text-sm font-medium m-2">
                        <a href="/privacy-policy">{t("Privacy Policy")}</a>
                    </div>
                    <div className="text-sm font-medium m-2">
                        <a href="/refund-policy">{t("Refund Policy")}</a>
                    </div>
                    <div className="text-sm font-medium m-2">
                        <a href="/terms-of-service">{ t("Terms of Service")}</a>
                    </div>
                    <div className="flex mx-1 my-6">
                        <a href="#" className="mx-1 sm:mx-3">
                            <img src={ LinkedIn } width="24rem" height="24rem" alt="icon" />
                        </a>
                        <a href="#" className="mx-1 sm:mx-3">
                            <img src={ Facebook } width="24rem" height="24rem" alt="icon" />
                        </a>
                        <a href="#" className="mx-1 sm:mx-3">
                            <img src={ Instagram } width="24rem" height="24rem" alt="icon" />
                        </a>
                        <a href="#" className="mx-1 sm:mx-3">
                            <img src={ Twitter } width="24rem" height="24rem" alt="icon" />
                        </a>
                    </div>
                </div>
                <div className="w-3/5 sl:w-[70%] flex justify-end pt-4 sm:w-full lm:w-full tab:w-full sm:flex-col">
                    { Object.entries( footerLinks ).map( ( [ key, value ], index ) => (
                        <div class="w-1/5 mx-2">
                            <h6 className="text-base font-semibold">{ key }</h6>
                            <div className="my-4">
                                <div className="text-sm font-medium my-2">
                                    { value.items.map( ( item, idx ) => (
                                        <div className="text-sm font-medium my-2 sm:my-6" key={ idx }>
                                            <a href={ item.link }>{ item.label }</a>
                                        </div>
                                    ) ) }
                                </div>
                            </div>
                        </div>
                    ) ) }
                </div>
            </div>
        </div>
    )
}